import React, { useCallback, useEffect, useMemo, useState } from 'react';
import API from '@aws-amplify/api';
import queryString from 'query-string';
import { Icon } from '@iconify/react';
import Select from 'react-dropdown-select';

import Spacer from '../../components/spacer/spacer.component';

import logo from './../../assets/logo.svg';
import styles from './home.module.scss';

const extraneousKeys = ['Shift', 'Control', 'Alt', 'Meta', 'CapsLock', 'Tab']; // Keys to ignore when scanning

const workshopOptions = [
  {
    workshop_id: 'verkada',
    value: 1,
    label: 'Verkada CEO: Filip Kaliszan',
  },
  {
    workshop_id: 'neo',
    value: 2,
    label: 'Neo CEO: Ali Partovi',
  },
  {
    workshop_id: 'convex',
    value: 3,
    label: 'Convex',
  },
  {
    workshop_id: 'intel_1',
    value: 4,
    label: 'Intel: Dev Set-Up & Example',
  },
  {
    workshop_id: 'terraapi',
    value: 5,
    label: 'TerraAPI',
  },
  {
    workshop_id: 'qed',
    value: 6,
    label: 'QED Protocol',
  },
  {
    workshop_id: 'together',
    value: 7,
    label: 'Together.ai',
  },
  {
    workshop_id: 'ava',
    value: 8,
    label: 'Ava Labs',
  },
  {
    workshop_id: 'bun',
    value: 9,
    label: 'Bun',
  },
  {
    workshop_id: 'codegen',
    value: 10,
    label: 'Codegen',
  },
  {
    workshop_id: 'fetch',
    value: 11,
    label: 'Fetch.ai: Build & Demo',
  },
  {
    workshop_id: 'intel_2',
    value: 12,
    label: 'Intel: Transformers on IDC',
  },
  {
    workshop_id: 'intersystems',
    value: 13,
    label: 'InterSystems',
  },
  {
    workshop_id: 'roblox',
    value: 14,
    label: 'Roblox',
  },
  {
    workshop_id: 'vespa',
    value: 15,
    label: 'Vespa',
  },
  {
    workshop_id: 'warp',
    value: 16,
    label: 'Warp',
  },
  {
    workshop_id: 'parrot',
    value: 17,
    label: 'Parrot Drones',
  },
  {
    workshop_id: 'terraSquat',
    value: 17,
    label: 'TerraAPI: Squat, Bench, Deadlift',
  },
  {
    workshop_id: 'taisu',
    value: 18,
    label: 'Taisu Ventures: Panel Disscussion',
  },
  {
    workshop_id: 'monster',
    value: 19,
    label: 'Monster API',
  },
  {
    workshop_id: 'pearvc',
    value: 20,
    label: 'PearVC',
  },
  {
    workshop_id: 'taisu_web3',
    value: 21,
    label: 'Taisu Ventures: web3 Startup Fireside',
  },
  {
    workshop_id: 'ethics',
    value: 22,
    label: 'Ethics in Society',
  },
  {
    workshop_id: 'assemble',
    value: 23,
    label: 'Fetch.ai: Assemble your Services',
  },
  {
    workshop_id: 'human_capital',
    value: 24,
    label: 'Human Capital with Tanay Tandon',
  },
  {
    workshop_id: 'chroma',
    value: 25,
    label: 'Chroma',
  },
  {
    workshop_id: 'yc',
    value: 26,
    label: 'YCombinator: Dalton Caldwell',
  },
  {
    workshop_id: 'humanoid',
    value: 27,
    label: '1X: Humanoid Robot',
  },
];

/**
 * Displays user information
 * @param {string} icon_name - The name of the icon
 * @param {string} value - The text to display
 */
const UserInfo = ({ icon_name, value }) => {
  return (
    <p className={['text-xl w-full flex flex-row items-center my-2'].join(' ')}>
      <Icon
        icon={icon_name}
        className={['text-2xl w-8 mr-1'].join(' ')}
      />
      {value}
    </p>
  );
};

const Meals = ({ logout }) => {
  const [logs, setLogs] = useState([]); // Stores timestamps of scanned codes - if a code is scanned within 1 minute of the previous code, it is approved
  const [scanning, setScanning] = useState(null);
  const [scannedCode, setScannedCode] = useState([]);
  const [isFocused, setIsFocused] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('scan'); // mode is "scan" if using a scanner, otherwise "manual" if using a phone
  const [workshopId, setWorkshopId] = useState(null);

  /**
   * Returns the current meal based on the time of day. Returns null if no meal is available
   */
  const getMeal = useCallback(() => {
    const hour = new Date().getHours();
    console.log('hour', hour);
    if (hour > 6 && hour < 10) {
      return 'Breakfast';
    } else if (hour > 11 && hour < 14) {
      return 'Lunch';
    } else if (hour > 16 && hour < 20) {
      return 'Dinner';
    } else {
      return null;
    }
  }, []);

  /**
   * Returns the current meal code based on the time of day. Returns null if no meal is available
   *
   * Format: <3 letter day>-<meal>
   * Example: fri-lunch
   *
   */
  const getMealCode = useCallback(() => {
    // 3 Letter week day
    const day = new Date()
      .toLocaleString('en-us', { weekday: 'short' })
      .toLowerCase();
    const meal = getMeal()?.toLowerCase();

    if (meal === null || meal === undefined) {
      return null;
    }

    return `${day}-${meal}`;
  }, [getMeal]);

  /**
   * Fetches user data from the API. Saves error to state if there was an error
   * @param {string} user_id - The user's id
   * @returns {object} - The user's data, or null if there was an error
   */
  const fetchUserData = useCallback(
    async (user_id) => {
      try {
        const userData = await API.get(
          'treehacks',
          `/users/${user_id}/forms/application_info`,
          {}
        );

        console.log('userData', userData, scanning);

        const userDataStatus = userData?.status || 200;

        if (userDataStatus !== 200) {
          setError(
            "Error: Could not fetch user's data (user may not exist)",
            'Status: ' + userDataStatus,
            '\n'
          );
          return null;
        }

        switch (scanning) {
          case 'meals':
            const mealList = await API.get(
              'treehacks',
              `/users/${user_id}/forms/used_meals`,
              {}
            );
            console.log('mealList', mealList);
            return {
              mealList: mealList,
              userData: userData,
            };
          case 'checkin':
            const checkIn = await API.get(
              'treehacks',
              `/users/${user_id}/forms/check_in`,
              {}
            );
            console.log('checkIn', checkIn);
            return {
              checkInStatus: checkIn,
              userData: userData,
            };
          case 'workshop':
            const workshopList = await API.get(
              'treehacks',
              `/users/${user_id}/forms/workshop_info`,
              {}
            );
            console.log('workshopList', workshopList);
            return {
              workshopList: workshopList,
              userData: userData,
            };
          default:
            return null;
        }
      } catch (error) {
        // Handle error
        console.log('error', error);
        setError(
          "Error: Could not fetch user's data (user may not exist) \n" +
            error?.message
        );
        return null;
      }
    },
    [scanning]
  );

  /**
   * Adds the user's id, status, and message to the logs. If the user was scanned within the last minute,
   * set status and message to the previous status
   * @param {string} user_id - The user's id
   * @param {boolean} status - The user's status
   * @param {string} message - The message to display
   */
  const addToLogs = (user_id, status, message) => {
    for (let i = logs.length - 1; i >= 0; i--) {
      const log = logs[i];

      if (log.code === user_id) {
        const logTime = new Date(log.time);
        const currentTime = new Date();

        const timeDiff = currentTime.getTime() - logTime.getTime();
        const seconds = timeDiff / 1000;
        const minutes = Math.floor(timeDiff / 1000 / 60);

        console.log('timeDiff', seconds, minutes, log.status, log.message);

        if (seconds < 30) {
          status = log.status;
          message = log.message;
        }
      }
    }

    setLogs((prevLogs) => [
      ...prevLogs,
      {
        code: user_id,
        status: status, //
        message: message,
        time: new Date().toLocaleString(),
      },
    ]);
  };

  /**
   * Handles scanning a user's code. Updates the user's meal list if the user is approved.
   * Saves the user's id, status, and timestamp to logs. To handle accidental double scans,
   * if a user is scanned within 1 minute of the previous scan, the user's status is set to the previous status.
   *
   * @param {string} user_id - The user's id
   */
  const handleScan = useCallback(
    async (user_id) => {
      setError(null); // Reset error

      const response = await fetchUserData(user_id);

      console.log(
        'response',
        response,
        error === null,
        response !== null,
        response?.userData
      );

      if (error === null && response !== null && response?.userData) {
        setUser({
          user_id: user_id,
          userData: response.userData,
        });

        console.log('user set', user, scanning);

        if (scanning === 'checkin') {
          const checkIn = response.checkInStatus?.checkInStatus || false;

          if (checkIn) {
            addToLogs(user_id, true, 'Check In Approved');
          } else {
            addToLogs(user_id, null, 'Check In Pending');
          }
        } else if (scanning === 'meals') {
          const mealList = response.mealList?.mealList || '';
          const mealCode = getMealCode();

          if (mealCode !== null) {
            const meals = mealList.split(' ');
            const status = !meals.includes(mealCode);
            const message = status
              ? 'Meal Scan Approved'
              : 'Meal Scan Denied (already scanned)';

            console.log('mealCode', mealCode, mealList, meals, status, message);

            if (status) {
              // Append meal code to user's meal list
              const updated_meal_info = mealList + ' ' + mealCode;
              await updateUserMeals(user_id, updated_meal_info);
              console.log('meal updated', updated_meal_info);
            }

            addToLogs(user_id, status, message);
          } else {
            setError('No meals available at this time');
          }
        } else if (scanning === 'workshop') {
          const workshopList = response.workshopList?.workshopList || '';
          const status = true;
          var message;

          // Check if workshopId is substring of workshopList
          if (!workshopList.includes(workshopId)) {
            // Append workshop code to user's workshop list
            const updated_workshop_info = workshopList + ' ' + workshopId;
            await updateUserWorkshops(user_id, updated_workshop_info);
            message = 'User Attendance Recorded';
          } else {
            message = 'User Attendance Already Recorded';
          }

          addToLogs(user_id, status, message);
        }
      }
    },
    [error, workshopId, scanning, logs, fetchUserData, getMealCode, setUser]
  );

  /**
   * Updates the border color of the scanning box based on the last scanned user's status
   * @returns {string} - The border color
   */
  const getBorderColor = useCallback(() => {
    if (!isFocused) {
      return 'shadow-md';
    } else if (logs.length > 0) {
      if (logs[logs.length - 1].status == null) {
        return 'shadow-lg shadow-blue-600 border-blue-600';
      } else if (logs[logs.length - 1].status) {
        return 'shadow-lg shadow-tree-green border-tree-green';
      } else {
        // Already scanned
        return 'shadow-lg shadow-red-500 border-red-500';
      }
    } else {
      // Scanning but no logs
      return 'border-slate-500';
    }
  }, [isFocused, logs]);

  /**
   * Updates the text color of the scanning box based on the last scanned user's status
   * @returns {string} - The text color
   */
  const getTextColor = useCallback(() => {
    if (logs[logs.length - 1].status == null) {
      return 'text-blue-600';
    } else if (logs[logs.length - 1].status) {
      return 'text-tree-green';
    } else {
      return 'text-red-500';
    }
  }, [logs]);

  /**
   * Updates the status icon of the scanning box based on the last scanned user's status
   * @returns {string} - The icon name of the status icon (from iconify)
   */
  const getStatusIcon = useCallback(() => {
    if (logs[logs.length - 1].status == null) {
      return 'fluent:shield-question-32-regular';
    } else if (logs[logs.length - 1].status) {
      return 'ph:seal-check-bold';
    } else {
      return 'solar:shield-cross-bold';
    }
  }, [logs]);

  /**
   * Handles when the window is focused
   */
  const onFocus = useCallback(() => {
    // console.log('Tab is in focus');
    setIsFocused(true);
  }, []);

  /**
   * Handles when the window is blurred
   */
  const onBlur = useCallback(() => {
    // console.log('Tab is blurred');
    setIsFocused(false);
    setScannedCode([]);
  }, []);

  /**
   * Handles when the scan button is clicked. Toggles scanning and resets scannedCode
   */
  const handleScanButton = useCallback(
    (scan_role) => {
      if (scanning === scan_role) {
        setScanning(null);
      } else {
        setScanning(scan_role);
      }
      setScannedCode([]);
    },
    [scanning]
  );

  const handleWorkshopSelect = (values) => {
    const selected_workshop_id = values[0].workshop_id;

    if (selected_workshop_id === workshopId) {
      setWorkshopId(null);
    } else {
      setWorkshopId(selected_workshop_id);
    }

    console.log('values', values[0].workshop_id);
  };

  const updateUserWorkshops = async (user_id, workshop_id) => {
    const payload = {
      body: {
        workshopList: workshop_id,
      },
    };

    try {
      const workshopResponse = await API.put(
        'treehacks',
        `/users/${user_id}/forms/workshop_info`,
        payload
      );

      console.log('wr', workshopResponse);
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  /**
   * Updates the user's meal list, given the user's id and updated meal list
   * @param {string} user_id - The user's id
   * @param {string} meal_info - The user's updated meal list
   */
  const updateUserMeals = async (user_id, meal_info) => {
    const payload = {
      body: {
        mealList: meal_info,
      },
    };

    try {
      const mealsResponse = await API.put(
        'treehacks',
        `/users/${user_id}/forms/used_meals`,
        payload
      );

      console.log('mr', mealsResponse);
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  /**
   * Updates the user's check in status, given the user's id and the status
   * @param {string} user_id - The user's id
   * @param {boolean} status - The user's check in status
   */
  const updateUserCheckIn = async (user_id, status) => {
    if (status) {
      const payload = {
        body: {
          checkInStatus: true,
        },
      };

      try {
        const checkInResponse = await API.put(
          'treehacks',
          `/users/${user_id}/forms/check_in`,
          payload
        );

        console.log('cr', checkInResponse);
      } catch (error) {
        // Handle error
        console.log(error);
      }

      // Update last log
      const lastLog = logs[logs.length - 1];
      lastLog.status = true;
      lastLog.message = 'Check In Approved';

      setLogs((prevLogs) => [...prevLogs.slice(0, -1), lastLog]);
    } else {
      // Update last log
      const lastLog = logs[logs.length - 1];
      lastLog.status = false;
      lastLog.message = 'Check In Denied';

      setLogs((prevLogs) => [...prevLogs.slice(0, -1), lastLog]);
    }
  };

  const showScanResult = useMemo(() => {
    console.log('mode', mode, scanning, workshopId);
    if (mode === 'manual') {
      return true;
    } else if (scanning === null) {
      return false;
    } else if (scanning === 'workshop' && workshopId === null) {
      return false;
    } else {
      return true;
    }
  }, [workshopId, mode, scanning]);

  /**
   * Detects if the user is scanning a code manually - like with their phone. If so, parse the code and treat it as a scan
   */
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);

    // Only manual scan if a button is clicked (if it's workshop, then workshopId must be selected too)
    if ('id' in queryParams && queryParams.id !== '' && scanning !== null) {
      setMode('manual');

      if (
        scanning !== 'workshop' ||
        (scanning === 'workshop' && workshopId !== null)
      ) {
        console.log('queryParams - sca', queryParams, scanning, workshopId);
        handleScan(queryParams['id']);
      }
    }
  }, [scanning, workshopId]);

  /**
   * Detects a key press. If the key is enter, parse all the keys pressed and filter out extraneous keys.
   * Then, join the array into a string and call the handleScan function with the string as the user id.
   */
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        const code = scannedCode
          .filter((x) => !extraneousKeys.includes(x)) // Remove extraneous keys
          .join(''); // Join the array into a string

        // console.log(code);

        const queryParams = queryString.parseUrl(code)['query'];

        console.log('queryParams - sca', queryParams);

        if ('id' in queryParams && queryParams.id !== '') {
          handleScan(queryParams['id']);
        }

        setScannedCode([]);
      } else {
        console.log(e.key);
        setScannedCode((prevScannedCode) => [...prevScannedCode, e.key]);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [scannedCode]); // Add scannedCode as a dependency

  /**
   * Detects if the window is focused or blurred. If the window is blurred, pause scanning.
   */
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <div className={['h-screen bg-[#fefafc]'].join(' ')}>
      {/* Navbar */}
      <nav
        className={[
          'sticky z-10 bg-white w-full shadow-md px-8 py-4 justify-center items-center flex font-roboto',
        ].join(' ')}>
        <div className={['max-w-6xl w-full flex'].join(' ')}>
          <img
            src={logo}
            alt='logo'
            className='h-12'
          />
          <p
            className={[
              'max-sm:hidden text-3xl ml-4 h-12 leading-[3rem] text-tree-green-light',
            ].join(' ')}>
            tree
            <span className={['font-bold'].join(' ')}>hacks</span> scanos
          </p>
          <Spacer />
          <p
            onClick={logout}
            className={[
              'max-sm:text-md text-lg ml-4 h-12 leading-[3rem] hover:text-tree-green hover:cursor-pointer text-slate-500',
            ].join(' ')}>
            log out
          </p>
        </div>
      </nav>

      {/* Body */}
      <div
        className={[
          styles.body,
          'font-roboto z-0 flex justify-center flex-col',
        ].join(' ')}>
        <p
          className={[
            styles.title,
            'text-4xl mt-12 text-center text-tree-green-light font-bold',
          ].join(' ')}>
          Scanos
        </p>

        {/* Display meal */}
        <p className={['text-center text-xl mt-6 mb-4'].join(' ')}>
          {/* Show day of the week */}
          {getMeal() === null
            ? 'No meals available'
            : new Date().toLocaleString('en-us', { weekday: 'long' }) +
              ' ' +
              getMeal()}
        </p>

        <div className={['flex flex-row justify-center'].join(' ')}>
          <p
            onClick={() => handleScanButton('checkin')}
            className={[
              'transition-all cursor-pointer border-2 border-transparent w-fit mx-4 mt-4 mb-2 px-6 py-2 rounded-lg text-lg',
              // 'text-white bg-tree-green-light hover:bg-white hover:border-tree-green'
              scanning === 'checkin'
                ? 'text-white bg-red-500 hover:bg-red-600'
                : `text-tree-green bg-white border-2 border-tree-green-light hover:bg-tree-green-light hover:text-white 
                     active:bg-tree-green active:text-white`,
            ].join(' ')}>
            {scanning === 'checkin' ? 'Stop Checkin' : 'Check In'}
          </p>

          <p
            onClick={() => handleScanButton('meals')}
            className={[
              'transition-all cursor-pointer border-2 border-transparent w-fit mx-4 mt-4 mb-2 px-6 py-2 rounded-lg text-lg',
              // 'text-white bg-tree-green-light hover:bg-white hover:border-tree-green'
              scanning === 'meals'
                ? 'text-white bg-red-500 hover:bg-red-600'
                : `text-tree-green bg-white border-2 border-tree-green-light hover:bg-tree-green-light hover:text-white 
                   active:bg-tree-green active:text-white`,
            ].join(' ')}>
            {scanning === 'meals' ? 'Stop Meals' : 'Meals'}
          </p>

          <p
            onClick={() => handleScanButton('workshop')}
            className={[
              'transition-all cursor-pointer border-2 border-transparent w-fit mx-4 mt-4 mb-2 px-6 py-2 rounded-lg text-lg',
              // 'text-white bg-tree-green-light hover:bg-white hover:border-tree-green'
              scanning === 'workshop'
                ? 'text-white bg-red-500 hover:bg-red-600'
                : `text-tree-green bg-white border-2 border-tree-green-light hover:bg-tree-green-light hover:text-white 
                    active:bg-tree-green active:text-white`,
            ].join(' ')}>
            {scanning === 'workshop' ? 'Stop Workshops' : 'Workshops'}
          </p>
        </div>

        {scanning === 'workshop' && (
          <Select
            className={['max-w-96 mx-auto mt-7 mb-2 font-roboto'].join(' ')}
            style={{
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem',
              fontSize: '1.2rem',
            }}
            options={workshopOptions}
            onChange={handleWorkshopSelect}
          />
        )}

        {/* If currently scanning, or mode is manual, show the scan status */}
        {showScanResult && (
          <div
            className={[
              'max-sm:w-4/5 mt-8 mx-auto p-8 transition-all flex justify-center items-center w-1/2 max-w-2xl min-h-96',
              'border-2 rounded-xl flex flex-col text-center',
              getBorderColor(),
            ].join(' ')}>
            {/* Check if window is focused */}
            {isFocused ? (
              <>
                {error !== null ? (
                  <p className={['text-2xl text-red-500'].join(' ')}>{error}</p>
                ) : (
                  <>
                    {/* Check if there are any scanned users */}
                    {logs.length > 0 ? (
                      <>
                        <p
                          className={[
                            'text-2xl mb-4 w-full flex flex-row justify-center items-center',
                            getTextColor(),
                          ].join(' ')}>
                          {logs[logs.length - 1].message}
                          <Icon
                            icon={getStatusIcon()}
                            className={['text-3xl w-8 ml-2'].join(' ')}
                          />
                        </p>

                        {user?.userData && (
                          <>
                            <UserInfo
                              icon_name='solar:user-linear'
                              value={
                                user?.userData?.first_name +
                                ' ' +
                                user?.userData?.last_name
                              }
                            />
                            <UserInfo
                              icon_name='lucide:school-2'
                              value={user?.userData?.university}
                            />
                            <UserInfo
                              icon_name='solar:square-academic-cap-linear'
                              value={
                                user?.userData?.level_of_study +
                                ' (' +
                                user?.userData?.graduation_year +
                                ')'
                              }
                            />
                            <UserInfo
                              icon_name='solar:user-id-linear'
                              value={user?.user_id}
                            />
                          </>
                        )}

                        {/* Status is only null when prompted for checkin */}
                        {scanning === 'checkin' &&
                          logs[logs.length - 1].status === null && (
                            <div
                              className={[
                                'flex flex-row justify-around w-full mt-8',
                              ].join(' ')}>
                              <button
                                onClick={() =>
                                  updateUserCheckIn(user?.user_id, true)
                                }
                                className={[
                                  `py-2 px-6 text-lg border-2 border-tree-green-light text-tree-green-light rounded-xl transition-all
                             hover:text-white hover:bg-tree-green-light 
                             active:bg-tree-green `,
                                ].join(' ')}>
                                Approve
                              </button>
                              <button
                                onClick={() =>
                                  updateUserCheckIn(user?.user_id, false)
                                }
                                className={[
                                  `py-2 px-6 text-lg border-2 border-red-500 text-red-500 rounded-xl transition-all 
                             hover:text-white hover:bg-red-500 
                            active:bg-red-700`,
                                ].join(' ')}>
                                Reject
                              </button>
                            </div>
                          )}
                      </>
                    ) : (
                      <p className={['text-2xl text-slate-500'].join(' ')}>
                        Scan away!
                      </p>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <p className={['text-2xl'].join(' ')}>Scanning Paused</p>
                <p className={['text-md mt-2 text-slate-500'].join(' ')}>
                  Click anywhere to resume
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Meals;
